<template>
  <CContainer>
    <CRow class="justify-content-center">
      <CCol md="6">
        <CCard class="p-4">
          <CCardBody>
            <CForm @submit.prevent="onSubmit" v-if="!success">
              <h1 class="text-center">
                {{ $t("public.header.password_reset") }}
              </h1>
              <p class="text-center text-muted">
                {{ $t("public.password_reset.info") }}
              </p>
              <CAlert show color="danger" v-for="item in errors" :key="item">{{
                item
              }}</CAlert>
              <CInput
                v-model.trim="$v.form.email.$model"
                @input="$v.form.email.$touch"
                :placeholder="$t('public.password_reset.placeholder_email')"
                autocomplete="email"
                :is-valid="isValid($v.form.email)"
                :invalid-feedback="
                  $t('validation_messages.enter_valid_email_addess')
                "
              >
                <template #prepend-content
                  ><CIcon name="cil-envelope-open"
                /></template>
              </CInput>

              <vue-ladda
                :loading="loading"
                data-style="zoom-in"
                button-class="btn btn-block btn-primary"
                type="submit"
              >
                {{ $t("public.password_reset.button_password_reset") }}
              </vue-ladda>
            </CForm>
            <div v-else>
              <h3 class="my-5">
                {{ $t("public.password_reset.success_message") }}
              </h3>
              <CButton
                color="primary"
                class="n-0"
                @click.prevent="emailNotRecevied"
                >{{
                  $t("public.password_reset.button_email_not_recevied")
                }}</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { apiUrl } from "../../http";

export default {
  name: "PasswordReset",
  data() {
    return {
      loading: false,
      success: false,
      errors: [],
      form: {
        captcha: "",
        email: "",
      },
    };
  },

  validations: {
    form: {
      email: { required, email },
    },
  },

  async mounted() {
    await this.$recaptchaLoaded();
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.showBadge();
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.$recaptchaInstance) {
      this.$recaptchaInstance.hideBadge();
    }
    next();
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      const isInvalid = this.$v.$invalid;
      if (!isInvalid) {
        this.loading = true;
        this.errors = [];
        try {
          this.form.captcha = await this.$recaptcha("password_reset");

          await this.axios.post(apiUrl(`/api/account/pwreset/`), {
            email: this.form.email,
            captcha: this.form.captcha,
          });
          this.form.email = "";
          this.loading = false;
          this.success = true;
        } catch (error) {
          this.loading = false;
          let errors = [];
          Object.keys(error.response.data).forEach((key) => {
            errors = errors.concat(error.response.data[key]);
          });
          this.errors = errors;
        }
      }
    },
    emailNotRecevied() {
      this.$v.$reset();
      this.errors = [];
      this.success = false;
    },
    isValid($field) {
      const status =
        $field.$dirty && $field.$invalid === true ? false : undefined;
      return status;
    },
  },
};
</script>
